var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v("Composition of an Alloy: Constants")]),_c('v-form',[_c('v-textarea',{attrs:{"label":"Table Caption","outlined":"","placeholder":"Write a caption for the constants table here."},model:{value:(_vm.inputs.constants_table_caption),callback:function ($$v) {_vm.$set(_vm.inputs, "constants_table_caption", $$v)},expression:"inputs.constants_table_caption"}}),_c('v-simple-table',[_c('thead',[_c('tr',[_c('td',[_vm._v(" ")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"center"}},[_vm._v("Value")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"center"}},[_vm._v("Units")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"center"}},[_vm._v("Reference")])])]),_vm._l((_vm.constants),function(ref){
var key = ref.key;
var label = ref.label;
return _c('tr',{key:key},[_c('th',[_vm._v(_vm._s(label))]),_c('td',[_c('calculation-input',{staticClass:"centered-input",model:{value:(_vm.inputs[("constants_" + key)]),callback:function ($$v) {_vm.$set(_vm.inputs, ("constants_" + key), $$v)},expression:"inputs[`constants_${key}`]"}})],1),_c('td',[_c('v-select',{attrs:{"items":_vm.units,"item-value":"key","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{attrs:{"content":item.label}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.label}})]}}],null,true),model:{value:(_vm.inputs[("constants_" + key + "_units")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("constants_" + key + "_units"), $$v)},expression:"inputs[`constants_${key}_units`]"}})],1),_c('td',[_c('v-text-field',{attrs:{"outlined":"","hide-details":""},model:{value:(_vm.inputs[("constants_" + key + "_reference")]),callback:function ($$v) {_vm.$set(_vm.inputs, ("constants_" + key + "_reference"), $$v)},expression:"inputs[`constants_${key}_reference`]"}})],1)])})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }